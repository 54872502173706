

















































































































































































































































































































import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    Dialog,
    Toast
} from "vant";
import Mixin from "@/js/mixins";

@Component({
    mixins: [Mixin]
})
export default class HomeAgent extends Vue {
    public user_id = "";
    public info = {}; // 用户信息
    public isShowAll = false; //是否显示全部
    showAll() {
        this.isShowAll = !this.isShowAll
        console.log(this.isShowAll)
    }
    // 获取用户信息
    getInfo(type) {
        let _this = this;
        this.$api.request({
            url: `${type}/member/get-info`,
            //   data:{user_id:this.user_id},
            success(res) {
                _this.info = res.data
                console.log(_this.info)
            }
        });
    }

    init() {
        this.info = {}
        this.getInfo(this.$route.query.type);
    }
}
